import * as React from "react";

import {
    HOME_URL,
    LOGIN_URL, REDIRECTION_PATHS,
    SIGN_UP_URL,PROP_ACT_URL
} from "./constants";

export const autoCompleteBold=(prefix,string)=>{
    if (prefix.length===0) return [{isBold:false,label:string}];
    let result=[]
    const lowerString=string.toLowerCase().trim();
    const lowerPrefix=prefix.toLowerCase().trim();
    const prefixLenght=prefix.length;
    let index=0;
    for(let i =0 ;i<=string.length-prefixLenght;i++){
        const current=lowerString.substring(i,i+prefixLenght)
        if(current===lowerPrefix) {
            if(index!==i) result=[...result,{isBold:false,label:string.trim().substring(index,i)},{isBold:true,label:string.trim().substring(i,i+prefixLenght)}];
            else result=[...result,{isBold:true,label:string.trim().substring(i,i+prefixLenght)}];
            index=i+prefixLenght;
            i=i+prefixLenght;
        }
        else if (i===string.length-prefixLenght) {
            result=[...result,{isBold:false,label:string.trim().substring(index,string.length)}]
            break;
        }
        if(i+1>string.length-prefixLenght) {
            result=[...result,{isBold:false,label:string.trim().substring(i,string.length)}]
            break;
        }
        }
    return result;
}
export const capitalizeFirstLetter = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
  };

 export const extractMenuAr = (storedMenuItems, menuLabel) => {
    for (const item of storedMenuItems) {
      for (const column of item.showMenu) {
        for (const menuItem of column.columnItems) {
          if (menuItem.Link.includes(menuLabel)) {
            return menuItem.Label;
          }
        }
      }
    }
    return [];
  };
export const openInNewTab = (url) => {
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
    if (newWindow) newWindow.opener = null
}
export const generateIndexFromString=(str, min, max)=> {
    let index = 0;
    for (let i = 0; i < str.length; i++) {
        index += str.charCodeAt(i) * (i + 1);
    }
    return (index % (max - min + 1)) + min;
}

export const  formatDate=(dateString)=> {
    const dateObj = new Date(dateString);
    const year = dateObj.getFullYear();
    const month = String(dateObj.getMonth() + 1).padStart(2, '0'); // Month is zero-based
    const day = String(dateObj.getDate()).padStart(2, '0');

    return `${year}-${month}-${day}`;
}

export const spaceTo_=(string)=>string.split(' ').join('_');


export const GetFlagByCode =({code,size})=> <img src={`https://flagsapi.com/${code}/flat/${size}.png`}/>

export const redirect = (url) => {
    window.location.href=url;
}
export const toHome=()=>{
    redirect( HOME_URL);
}
export const toLogin=()=>{
    redirect( LOGIN_URL);
}
export const toPropActivity=()=>{
    redirect( PROP_ACT_URL);
}
export const toSignUp=()=>{
    redirect(SIGN_UP_URL);
}
export const isEmptyString =(s)=>s.length===0;

export const customNavigate=(link,redirectAll=true,newTab=false)=>{
  if(REDIRECTION_PATHS.some(item=>item.path===link)) {
            if(!newTab)  redirect((REDIRECTION_PATHS.find(item=>item.path===link)).link)
            else openInNewTab((REDIRECTION_PATHS.find(item=>item.path===link)).link)
        }
        else{
             redirect(link);
        }
}

export const scrollToTopOnMount = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
};

