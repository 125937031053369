import styles from './publicinquiry.module.css';
import BreadCrumb from "../../components/breadcrumb/BreadCrumb";
import globalStyles from "../globalstyles.module.css";
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import GlobalSearch from "../../components/search/GlobalSearch";
import {FilterButton} from "../../components/filters/FilterButton";
import {Select} from "../../components/select/Select";
import classNames from "classnames";
import {
    InquiryCard,
    InquiryCardMobile,
    InquiryCardMobileSkeleton,
    InquiryCardSkeleton
} from "../../pagescomponents/inquirycard/InquiryCard";
import {useGetAllCommune, useGetAllProvince, useGetIamUrl, useGetInquiriesData} from "../../api/caller";
import {Pagination} from "antd";
import Input from "../../components/input/Input";
import '@react-pdf-viewer/zoom/lib/styles/index.css';
import Modal from "../../components/modal/Modal";
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import PdfViewerModal from "../../components/modal/PdfViewerModal";
import useHandleClassName from "../../hooks/useHandleClassName";
import {isEmptyString, openInNewTab} from "../../util/functionsUtil";
import useHandleTranslation from "../../hooks/useHandleTranslation";
import {EmptySearchData} from "../../components/emptyData/EmptyData";
import { useNavigate } from 'react-router-dom';
import NewInquiry from '../newInquiry/newInquiry';

const publicInquiry=(props)=>{
    const {currLng}=useHandleTranslation();
    const [inquiryCriteria, setInquiryCriteria] = useState({
        page: 1,
        size: 8,
        search: "",
        commune: "",
        province:"",
        reference: "",
    });

    const getInquiriesData=useGetInquiriesData(inquiryCriteria,currLng);
    const {t:tBC}=useTranslation("breadcrumb");
    const {t:tEP}=useTranslation("enquete");
    const [showFilter,setShowFilter]=useState(false);
    const getAllProvince=useGetAllProvince(true,currLng);
    const getAllCommune=useGetAllCommune(inquiryCriteria.province,currLng);
    const filterItemsContainerStyle=classNames({
        [styles.filterItemsContainer]:true,
        [styles.notShow]:!showFilter,
        [styles.filterItemsContainerAppear]:showFilter,
    })
    const [pdfViewerModalParams,setPdfModalParams]=useState({isOpen:false,url:""})
    const [isWarningModalOpen,setWarningModalStatus]=useState(false);
    const [warningModalArgs,setWarningModalArgs]=useState("");

    const [searchCriteria, setSearchCriteria]=useState("");
    const {translate}=useHandleClassName();
    const breadcrumbParts = [
        {text: tBC("Accueil"), link: '/'},
        {text: tBC("je_suis_mon_dossier"), link: '/'},
        {text: tBC("enquête_publique"), link: '/'},
    ];
    const navigate=useNavigate();
    const [reference, setReference] = useState("");
    const [isClicked, setIsClicked] = useState(false);
    const { data: result, loading, error: hookError,refetch } = useGetIamUrl(currLng,reference, isClicked);
    
   
    useEffect(() => {
        const element = document.getElementById('page');
        if (getInquiriesData.isFetching && element) {
            element.scrollIntoView({ behavior: 'smooth' });
        }
    }, [getInquiriesData.isFetching]);

    const onSearchClick=()=>{
        if (searchCriteria===inquiryCriteria.search && inquiryCriteria.noPage===1) getInquiriesData.refetch();
        else{
            if(searchCriteria.length !==0) setInquiryCriteria({...inquiryCriteria,page: 1,search:searchCriteria});
        }
    }
    const onSearchChange=(string)=>{
        setSearchCriteria(string);
        if (string.length===0) setInquiryCriteria({...inquiryCriteria,search: ""});
    }
    const onChangeFilterButton=(isOpen)=>{
        setShowFilter(isOpen);
    }

    const onReferenceChange=(reference)=>{
    setInquiryCriteria({...inquiryCriteria,reference:reference??""});
    }
    const onPaginationChange=(page, pageSize)=>{
        setInquiryCriteria({...inquiryCriteria,page: page})
    }
    const onProvinceChange=(province)=>{
        setInquiryCriteria({...inquiryCriteria,province:province ?? ""});
    }
    const onCommuneChange=(commune)=>{
        setInquiryCriteria({...inquiryCriteria,commune:commune??""});
    }

    const onCommuneSelectClick= (isOpen)=>{
        if(!isOpen)getAllCommune.refetch();
    }
     const previewDocument=(url)=>{
        console.log("previewDocument "+url)
        setPdfModalParams({isOpen: true,url: url});
     }
    const previewDocumentOnMobile=(url)=>{
        const encodeurl=encodeURIComponent(url);
        console.log(encodeurl);
        openInNewTab(`/viewPdf/${encodeurl}`);
    }
     const onAddOppositionClick=(data,item)=>{
       // setWarningModalStatus(true);
        //setWarningModalArgs(commune);
        console.log("item==",item);
        localStorage.clear();
        if(item){
            localStorage.setItem('reference', item.reference);
            localStorage.setItem('adresse', item.adresse);
            localStorage.setItem('commune', item.commune);
            localStorage.setItem('dateDebut', item.dateDebut);
            localStorage.setItem('dateFin', item.dateFin);
            localStorage.setItem('document', item.document);
            localStorage.setItem('province', item.province);
            localStorage.setItem('titre', item.titre);
            localStorage.setItem('image', item.image);
            localStorage.setItem('date_restante', item.date_restante);
            localStorage.setItem('selectedLanguage', currLng);
        }
        if(data){
            setReference(data);
        }
        setIsClicked(true);
       if(refetch){
        refetch();
       
        console.log("result",result)
       }
        
    
     }
     if(result){
       window.location.href = result.url; 
        }
     const  handleClose=()=>{
        setWarningModalStatus(false);
        setPdfModalParams({...pdfViewerModalParams,isOpen: false});
     }
    return (
    <div id="page">
        <div className="breadcrumb">
            <BreadCrumb parts={breadcrumbParts}/>
        </div>
        <div className={`${globalStyles.pageTitleContainer} ${globalStyles.responsiveWeb}`}>
            <div className={globalStyles.pageTitle}>{tEP("page_title")}</div>
            <div className={globalStyles.pageTitleDescription}>{tEP("page_description")}</div>
        </div>

        <div className={`${globalStyles.pageTitleContainerMobile} ${globalStyles.responsiveMobile}`}>
            <div className={globalStyles.pageTitleMobile}>{tEP("page_title")}</div>
        </div>
        <div className={styles.pageContentContainer}>
            <div className={styles.searchContainer}>
                <div className={styles.searchItemContainer}>
                    <GlobalSearch loading={getInquiriesData.isFetching} onChange={onSearchChange} onClick={onSearchClick}
                                  menu={[]}
                                  placeholder={tEP("page_global_search_placeholder")} />
                </div>
                <div className={styles.filterContainer}>
                    <FilterButton onChange={onChangeFilterButton}/>
                </div>
            </div>
            <div className={filterItemsContainerStyle}>
                <Select label={tEP("page_filter_1_label")} options={getAllProvince.data?.page} onChange={onProvinceChange}
                        loading={getAllProvince.isFetching} search erasable/>
                <Select disabled={isEmptyString(inquiryCriteria.province)} label={tEP("page_filter_2_label")} options={getAllCommune.data?.page}
                        onChange={onCommuneChange} loading={getAllCommune.isFetching} onClick={onCommuneSelectClick} search erasable/>
                <Input placeholder={tEP("page_filter_3_label")} onChange={onReferenceChange}/>
            </div>
            <div className={styles.inquiryContentContainer}>
                {getInquiriesData.isFetched && !getInquiriesData.isFetching && getInquiriesData.data?.page && getInquiriesData.data.page.map((item, index) =>
                  <>
                    <InquiryCard key={2*index} className={globalStyles.responsiveWeb} data={item} onDecisionClick={previewDocument} onAddOppositionClick={() => onAddOppositionClick(item.reference, item)} withAction  />
                    <InquiryCardMobile key={2*index-1} className={globalStyles.responsiveMobile} data={item} onDecisionClick={previewDocumentOnMobile}  onAddOppositionClick={onAddOppositionClick} withAction  />
                  </>
            )
                }
                {!getInquiriesData.isFetching && getInquiriesData.data?.pagination.totalPage===0 &&
                    <div className={globalStyles.emptyResultContainer}>
                        <EmptySearchData/>
                    </div>



                }
                {getInquiriesData.isFetching && Array.from({length: 8}).map((element, index) =>
                    <>
                    <InquiryCardSkeleton key={2*index} className={globalStyles.responsiveWeb} withAction/>
                    <InquiryCardMobileSkeleton key={2*index-1} className={globalStyles.responsiveMobile} withAction/>
                    </>
                )}
            </div>
            {getInquiriesData.isFetched && getInquiriesData.data?.pagination.totalPage!==0 &&
                <div className={styles.paginationContainer}>
                    <Pagination current={inquiryCriteria.page} onChange={onPaginationChange}
                                pageSize={inquiryCriteria.size} total={getInquiriesData.data?.pagination?.totalPage}
                                showSizeChanger={false}/>
                </div>
            }
                </div>

            <PdfViewerModal isOpen={pdfViewerModalParams.isOpen} url={pdfViewerModalParams.url} onClose={handleClose}/>
            <Modal isOpen={isWarningModalOpen}>
            <>
                <div className={`ed-modal-title ${translate("bold")}`}>{tEP("page_modal_alert_title")}</div>
                <div className={`ed-modal-message ${translate("regular")}`}>
                    {tEP("page_modal_alert_description")}  {warningModalArgs} .
                </div>
                <button className={`ed-modal-close-btn ${translate("regular")}`} onClick={handleClose}>
                    {tEP("page_modal_alert_button_label")}
                </button>
            </>
             </Modal>
   
                </div>
                );
            }


export default publicInquiry;