import PropTypes from 'prop-types';
import TEmptyBody from './TEmptyBody.jsx';
import TDataBody from './TDataBody.jsx';
import {ConfigProvider, Spin} from "antd";
import {TLoadingBody} from "./TLoadingBody";

export const TBody = ({
                          isLoading,
                          isError,
                          isSuccess,
                          page,
                          prepareRow,
                          bodyProps,
                          actions,
                          onRowClick,
                          headerGroups,
                          emptyDataSecondaryMessage,
                          emptyDataPrimaryMessage,
                      }) => {
    if (isLoading) {
        return <TLoadingBody columnSize={headerGroups[0].headers.length} />;
    } else if (isError) {
        return (
            <TEmptyBody
                columnSize={headerGroups[0].headers.length}
                error={true}
                primaryMessage={emptyDataPrimaryMessage}
            />
        );
    } else if (isSuccess) {
        if (page.length === 0) {
            return (
                <TEmptyBody
                    columnSize={headerGroups[0].headers.length}
                    secondaryMessage={emptyDataSecondaryMessage}
                    primaryMessage={emptyDataPrimaryMessage}
                />
            );
        } else {
            return (
                <TDataBody
                    rows={page}
                    prepareRow={prepareRow}
                    bodyProps={bodyProps}
                    actions={actions}
                    onRowClick={onRowClick}
                />
            );
        }
    } else {
        return null;
    }
};

export default TBody;

TBody.propTypes = {
    prepareRow: PropTypes.func,
    onRowClick:PropTypes.func,
    bodyProps: PropTypes.func,
    actions: PropTypes.array,
    page: PropTypes.array,
    isLoading: PropTypes.bool,
    isError: PropTypes.bool,
    isSuccess: PropTypes.bool,
    headerGroups: PropTypes.array,
    emptyDataSecondaryMessage: PropTypes.string,
    emptyDataPrimaryMessage: PropTypes.string,
};