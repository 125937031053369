import {
  getArticleDataByCriteria,
  getArticleDataById,
  getAutoCompleteArticle,
  getFolderDataByReference,
  getTagsData,
  getActivityDataByCriteria,
  getActivityDetail,
  getKarazalResourceCommune,
  getKarazalResourceProvince,
  getAutoCompleteActivity,
  getKarazalResourceActivityNatures,
  getRankingFilterCriteria,
  getRankingFilterManners,
  getRankingData,
  getInquiriesData,
  getKarazalResourceDepartments,
  getDynamicList,
  getSignUpProcedures,
  sendSignUpData,
  getSignUpConfirmation,
  getArticleDataByMenu,
  getCheckTokenResetPassword,
  sendResetPassword,
  sendEmailResetPassword,
  getArticleEvidence,
  getRefJuridiqueDataByCriteria,
  getAutoCompleteRefJuridique,
  addNewProposation,
  getFactRefection,
  getFactReception,
  getFactDemolition,
  getFactDossier,
  getAllPermitsData,
  getCommuneListData,
  getPCPHPermitsData,
  getPrefectureListData,
  getCOMMUNEListNames,
  getAllDossierByCin,
  fetchMergedDataEco,
  checkIfCinExiste,
  getLocationData,
  getIamUrl,
  addNewOpposition
} from "./api";


import {useMutation, useQuery} from "react-query";
import {useHandleStandardResult} from "../hooks/useHandleApi";
import {GET_CHECK_TOKEN_RESET_PASSWORD, POST_RESET_PASSWORD, POST_SEND_MAIL_RESET_PASSWORD} from "./apiPath";

export const useGetFolderDataByReference = (reference,enabled,lng) => {
    return useQuery(
        ['suivi-dossier',reference,lng],
        async () => getFolderDataByReference(reference,lng),
        {
            enabled: enabled,
            retry: 1,
            placeholderData: { "timestamp": 0,"fr":['data'],"ar":['data'] },
            keepPreviousData: false,
            cacheTime: 0,
        }
    );
};
export const useGetArticleDataByCriteria = (articleCriteria) => {
    return useQuery(
        ['articles',articleCriteria],
        async () => getArticleDataByCriteria(articleCriteria.noPage,articleCriteria.pageSize,articleCriteria.search,articleCriteria.tags,articleCriteria.menu,articleCriteria.lng),
        {
            retry: 0,
            keepPreviousData: false,
            cacheTime: 0,
        }
    );
};
export const useGetTagsData = (menu,lng) => {
    return useQuery(
        ['articles',menu,lng],
        async () => getTagsData(menu,lng),
        {
            retry: 1,
            keepPreviousData: false,
            cacheTime: 0,
        }
    );
};
export const useGetAutoCompleteArticle=(prefix,menu,lng)=>{
    return useQuery(
        ['articles',prefix,menu,lng],
        async () => getAutoCompleteArticle(prefix,menu,lng),
        {
            retry: 1,
            keepPreviousData: false,
            cacheTime: 0,
        }
    );
}
export const useGetArticleDataById = (articleId,lng) => {
    return useQuery(
        ['article',articleId,lng],
        async () => getArticleDataById(articleId,lng),
        {
            retry: 1,
            keepPreviousData: false,
            cacheTime: 0,
        }
    );
};
export const useGetArticleDataByMenu= (menu,lng) => {
    return useQuery(
        ['article',menu,lng],
        async () => getArticleDataByMenu(menu,lng),
        {
            retry: 1,
            keepPreviousData: false,
            cacheTime: 0,
        }
    );
};
export const useGetRefActivitiesByCriteria = (activityCriteria,lng) => {
    return useQuery(
        ['activities',activityCriteria,lng],
        async () => getActivityDataByCriteria(activityCriteria.noPage,activityCriteria.pageSize,activityCriteria.search,activityCriteria.type,activityCriteria.nature,lng),
        {
            retry: 1,
            keepPreviousData: false,
            cacheTime: 0,
        }
    );
};
export const useGetAllProvince = (enabled,lng) => {
    return useQuery(
        ['province',lng],
        async () =>getKarazalResourceProvince(lng),
        {
            enabled:enabled,
            retry: 1,
            keepPreviousData: false,
            cacheTime: 0,
        }
    );
};
export const useGetAllCommune = (province,lng,prop,disabled=false) => {
    return useQuery(
      ["commune", province, lng],
      async () => getKarazalResourceCommune(province, lng, prop),
      {
        enabled: disabled,
        retry: 1,
        keepPreviousData: false,
        cacheTime: 0,
      }
    );
};
export const useGetLocationData = (
  bounds,
  cle,
  datedepotmin,
  datedepotmax,
  dateauthmin,
  dateauthmax,
  disabled = false
) => {
  return useQuery(
    [
      "locationData",
      bounds,
      cle,
      datedepotmin,
      datedepotmax,
      dateauthmin,
      dateauthmax,
    ],
    async () =>
      getLocationData(
        bounds,
        cle,
        datedepotmin,
        datedepotmax,
        dateauthmin,
        dateauthmax
      ),
    {
      enabled: !disabled, 
      retry: 1, 
      keepPreviousData: false, 
      cacheTime: 0, 
    }
  );
};
export const useGetActivityDetail = (communeId="",activityCode="",lng,enabled) => {
    return useQuery(
        ['activity',communeId,lng],
        async () =>getActivityDetail(communeId,activityCode,lng),
        {
            enabled:enabled,
            retry: 0,
            keepPreviousData: false,
            placeholderData:{},
            cacheTime: 0,
        }
    );
};
export const useGetAutoCompleteActivity=(prefix,menu,lng)=>{
    return useQuery(
        ['complete-activity',prefix,menu,lng],
        async () => getAutoCompleteActivity(prefix,menu,lng),
        {
            retry: 1,
            keepPreviousData: false,
            cacheTime: 0,
            placeholderData:()=>({fr:[],ar:[]})
        }
    );
}
export const useGetActivityNatures=(type,lng)=>{
    return useQuery(
        ['activity-natures',type,lng],
        async () => getKarazalResourceActivityNatures(type,lng),
        {
            retry: 1,
            keepPreviousData: false,
            cacheTime: 0,
            
        }
    );
}
export const useGetRankingFilterCriteria = (criteria,lng) => {
    return useQuery(
        ['ranking-filter',criteria,lng],
        async () => getRankingFilterCriteria(criteria,lng),
        {
            retry: 1,
            keepPreviousData: false,
            cacheTime: 0,
            placeholderData:()=>([])
        }
    );
};
export const useGetRankingFilterManners= (lng) => {
    return useQuery(
        ['ranking-filter',lng],
        async () => getRankingFilterManners(lng),
        {
            retry: 1,
            keepPreviousData: false,
            cacheTime: 0,
            placeholderData:()=>([])
        }
    );
};
export const useGetRankingData = (rankingCriteria,lng) => {
    return useQuery(
        ['ranking-data',rankingCriteria,lng],
        async () => getRankingData(
            rankingCriteria.page,
            rankingCriteria.size,
            rankingCriteria.search,
            rankingCriteria.periode,
            rankingCriteria.region,
            rankingCriteria.nbRanking,
            rankingCriteria.localite,
            rankingCriteria.sortField.id,
            !rankingCriteria.sortField.desc,
            lng
        ),
        {
            retry: 1,
            keepPreviousData: false,
            cacheTime: 0,
            placeholderData:()=>({page:[],total:0})
        }
    );
};
export const useGetInquiriesData=(inquiriesCriteria,lng)=>{
    return useQuery(
        ['inquiries-data',inquiriesCriteria,lng],
        async () => getInquiriesData(
            inquiriesCriteria.page,
            inquiriesCriteria.size,
            inquiriesCriteria.search,
            inquiriesCriteria.commune,
            inquiriesCriteria.reference,
            inquiriesCriteria.province,
            lng
        ),
        {
            retry: 1,
            keepPreviousData: false,
            cacheTime: 0,
            placeholderData:()=>({page:[],pagination:0})
        }
    );
}
export const useGetAllDepartements = (lng,profile,domaine,search) => {
    return useQuery(
        ['departements',lng,profile,domaine,search],
        async () =>getKarazalResourceDepartments(lng,profile,domaine,search),
        {
            retry: 1,
            keepPreviousData: false,
            cacheTime: 0,
        }
    );
};
export const useGetDynamicList = (listName) => {
    return useQuery(
        ['dynamiclist',listName],
        async () =>getDynamicList(listName),
        {
            retry: 1,
            keepPreviousData: false,
            cacheTime: 0,
            placeholderData:()=>([])
        }
    );
};
export const useGetSignUpProcedures = (lng,profile) => {
    return useQuery(
        ['sign-procedures',lng,profile],
        async () =>getSignUpProcedures(lng,profile),
        {
            retry: 1,
            keepPreviousData: false,
            cacheTime: 0,
        }
    );
};
export const usePostSignUpData = () => {
    const mutationQuery=useMutation( ({data,lng}) =>sendSignUpData(data,lng),
        {
            retry: 0,
        }
    );
    const customHandler=useHandleStandardResult(mutationQuery);
    return {...mutationQuery,customHandler}
};
export const useGetSignUpConfirmation = (object) => {
    return useQuery(
        ['sign-confirm',object],
        async () =>getSignUpConfirmation(object),
        {
            retry: 1,
            keepPreviousData: false,
            cacheTime: 0,
        }
    );
};

export const useSendEmailResetPassword=  () => {
    const mutationQuery =useMutation(
        (email) =>sendEmailResetPassword(email),
        {
            retry: 0,
        }
    );
    const customHandler=useHandleStandardResult(mutationQuery);
    return {...mutationQuery,customHandler}
}

export const useSendResetPassword=  () => {
    const mutationQuery =useMutation(
        ({token,password,repassword}) =>sendResetPassword(token,password,repassword),
        {
            retry: 0,
        }
    );
    const customHandler=useHandleStandardResult(mutationQuery);
    return {...mutationQuery,customHandler}
}

export const useCheckTokenResetPassword=  (token) => {
    const reactquery= useQuery(
        ['sign-reset-password',token],
        async () =>getCheckTokenResetPassword(token),
        {
            retry: 1,
            keepPreviousData: false,
            cacheTime: 0,
        }
    );
    const customHandler=useHandleStandardResult(reactquery);
    return {...reactquery,customHandler}
}
export const  useGetArticleEvidence=(lng)=>{
    return useQuery(
        ['article-evidence',lng],
        async () =>getArticleEvidence(lng),
        {
            retry: 1,
            keepPreviousData: false,
            cacheTime: 0,
        }
    );

}
export const useGetRefJuridiqueyCriteria = (refActCriteria,lng) => {
    console.log("Type: ",refActCriteria.type);
    console.log("Category: ",refActCriteria.category);
    return useQuery(
        ['refJuridique',refActCriteria,lng],
        async () => getRefJuridiqueDataByCriteria(refActCriteria.noPage,refActCriteria.pageSize,refActCriteria.search,refActCriteria.type,refActCriteria.category,lng),
        {
            retry: 1,
            keepPreviousData: false,
            cacheTime: 0,
        }
    );
};

//(prefix,menu,lng)
export const useGetAutoCompleteRefJur=(prefix,category,type,lng)=>{
    return useQuery(
        ['complete-ref-jurd',prefix,category,type,lng],
        async () => getAutoCompleteRefJuridique(prefix,category,type,lng),
        {
            retry: 1,
            keepPreviousData: false,
            cacheTime: 0,
            placeholderData:()=>({fr:[],ar:[]})
        }
    );
    
}
export const useAddNewProposation = () => {
  const mutationQuery = useMutation(
    (proposationData) => addNewProposation(proposationData),
    {
      retry: 0,
    }
  );
  const customHandler = useHandleStandardResult(mutationQuery);
  return { ...mutationQuery, customHandler };
};


// export const useGetPcphPermitsData = (
//   page,
//   apiChoice ,
//   enabled
// ) => {
//   return useQuery([page], () => getPCPHPermitsData(apiChoice, page), {
//     enabled: enabled,
//     retry: 1,
//     keepPreviousData: false,
//     cacheTime: 0,
//     placeholderData: () => ({
//       construire: [],
//       habiter: [],
//     }),
//   });
// };

export const useGetCommuneListData = (page = 10, enabled = true) => {
  return useQuery(["communeList", page], () => getCommuneListData(), {
    enabled: enabled,
    retry: 1,
    keepPreviousData: false,
    cacheTime: 0,
    placeholderData: () => ({
      CommuneList: [],
    }),
  });
};

export const useGetPrefectureListData = (page = 10, enabled = true,prefecture) => {
  return useQuery(["prefectureList", page], () => getPrefectureListData(), {
    enabled: enabled,
    retry: 1,
    keepPreviousData: false,
    cacheTime: 0,
    placeholderData: () => ({
      prefectureList: [],
    }),
  });
};

export const useGetcommunePerPrefecture = (
  page = 10,
  enabled = true,
  prefecture
) => {
  return useQuery(
    ["communeListPref", page, prefecture],
    () => getCOMMUNEListNames(page, prefecture), 
    {
      enabled: enabled,
      retry: 1,
      keepPreviousData: false,
      cacheTime: 0,
      placeholderData: () => (['']),
    }
  );
};

export const useGetDataEco = (page = 17, enabled = true, prefecture) => {
  return useQuery(
    ["listEcoData", page, prefecture],
    () => fetchMergedDataEco(page, prefecture),
    {
      enabled: enabled,
      retry: 1,
      keepPreviousData: false,
      cacheTime: 0,
      placeholderData: () => [""],
    }
  );
};




export const useGetAllPermitsData = (maitreOCin,searchTerm,lng,page = 20,enabled = true,) => {
   console.log("lng==",lng);
    return useQuery(
        ['all-permits', maitreOCin, page,lng],
        () => getAllDossierByCin(maitreOCin,searchTerm, page,lng),
        {
            
            enabled: enabled,  
            retry: 1,         
            keepPreviousData: false, 
            cacheTime: 0,     
        }
    );
}


export const checkCinIfExiste = (maitreOCin) => {
    const mutationQuery = useMutation(
      (maitreOCin) => checkIfCinExiste(maitreOCin),
      {
        retry: 0,
      }
    );
    const customHandler = useHandleStandardResult(mutationQuery);
    return { ...mutationQuery, customHandler };
  };
  export const  useGetIamUrl=(lng,reference,isClicked)=>{
    console.log("reference=",reference)
    return useQuery(
        ['url-iam',lng,reference],
        async () =>getIamUrl(lng,reference),
        {
            enabled : isClicked,
            retry: 1,
            keepPreviousData: false,
            cacheTime: 0,
            
        }
    );

}

export const useAddNewOpposition = () => {
    
    const mutationQuery = useMutation(
      (opposition) => addNewOpposition(opposition),
      {
        retry: 0,
      }
    );
    const customHandler = useHandleStandardResult(mutationQuery);
    return { ...mutationQuery, customHandler };
  };
  