import {useTranslation} from "react-i18next";
import styles from "../../pages/sign/sign.module.css";
import React from "react";
import {redirect} from "../../util/functionsUtil";
import {LOGIN_URL} from "../../util/constants";
import {useNavigate} from "react-router-dom";

const LegalFooter = () => {
    const navigate=useNavigate();
    const {t:tS}=useTranslation("signup")
    const login=()=>{
        redirect( LOGIN_URL);
    }

    return (
        <div className="py-2">
            {<div className={styles.singIn}>
                {tS("legal_footer_label1")}
                <a className={styles.signLink} onClick={login}>{tS("login_button")}</a>
            </div>}
            <div className={styles.legalFooter}>
                <div className="flex gap-1">
                    <p>{tS("legal_footer_label2_1")}</p>
                    <p>|</p>
                    <p className={styles.legalFooterElements} onClick={()=>navigate("/articles/article/aP_y2ZMBcgdv9khPOISH")}>{tS("legal_footer_label2_2")}</p>
                    <p>|</p>
                    <p className={styles.legalFooterElements} onClick={()=>navigate("/articles/article/ZP9RupMBcgdv9khPiIRt")}>{tS("legal_footer_label2_3")}</p>
                </div>

                <div className={"flex justify-center gap-6 items-center"}>
                    {tS("legal_footer_label3")}
                </div>
            </div>
        </div>
    )
}
export default LegalFooter;