import {useTranslation} from "react-i18next";
import BreadCrumb from "../../components/breadcrumb/BreadCrumb";
import globalStyles from "../globalstyles.module.css";
import styles from "./newInquery.module.css";
import useHandleClassName from "../../hooks/useHandleClassName";
import InputForm from "../../components/input/InputForm";
import TextAreaForm from "../../components/input/TextAreaForm";
import Btn from "../../components/button/Btn";
import { useForm } from "react-hook-form";
import { useAddNewOpposition } from "../../api/caller";
import { useLocation } from "react-router-dom";
import { useEffect,useState } from "react";
import {
  InquiryCard,
  InquiryCarMobile
} from "../../pagescomponents/inquirycard/InquiryCard";
import Cookies from "js-cookie";
import useHandleTranslation from "../../hooks/useHandleTranslation";
import Modal from "../../components/modal/Modal";
import {useNavigate} from "react-router-dom";
import {yupResolver} from "@hookform/resolvers/yup";
import {NEW_ENQUETE_SCHEMA} from "../../holders/schemas";
const NewInquiry=()=>{
const {t:tEP}=useTranslation("enquete");
const {t:tBC}=useTranslation("breadcrumb");
const {translate}=useHandleClassName();
 const { register,setValue,formState: { errors }, handleSubmit }= useForm({
        resolver: yupResolver(NEW_ENQUETE_SCHEMA ),
    }); 
 //   const { mutate: addNewOpposition, isLoading, isError, customHandler, data: result } = useAddNewOpposition();
      const addNewOpposition=useAddNewOpposition();
  
 const [isDataLoaded, setIsDataLoaded] = useState(false);
 const [reference, setReference] = useState(false);
 const [dateDebut, setDateDebut] = useState(false);
 const [dateFin, setDateFin] = useState(false);
 const [commune, setCommune] = useState(false);
 const [ville, setVille] = useState(false);
 const [prefecture, setPrefecture] = useState(false);
 const [nom, setNom] = useState("");
 const [prenom, setPrenom] = useState("");
 const [gender, setGender] = useState("");
 const [nationalite, setNationalite] = useState("");
 const [cin, setCin] = useState("");
 const [birthdate, setBirthDate] = useState("");
 const [birthPlace, setBirthPlace] = useState("");
 const [adresse, setAdresse] = useState("");
 const [city, setCity] = useState("");
 const [country, setCountry] = useState("");
 const {currLng,isLngFr,isLngAr}=useHandleTranslation();
const [modalTitle, setModalTitle] = useState("");
const [isModalOpen, setIsModalOpen] = useState(false);
const breadcrumbParts = [
    {text: tBC("Accueil"), link: '/'},
    {text: tBC("je_suis_mon_dossier"), link: '/'},
    {text: tBC("enquête_publique"), link: '/'},
];
const [item, setItem] = useState({
  reference: "",
  adresse: localStorage.getItem("reference"),
  titre : "",
  province : "",
  document : "",
  dateFin :"",
  dateDebut : "",
  image : ""

});
const location = useLocation()
const navigate = useNavigate();
const [isSuccess, setIsSuccess] = useState(false);
const [showFields, setShowFields] = useState(false);
const [modalMessage, setModalMessage] = useState(null);

const [icon, setIcon] = useState(null);
useEffect(() => {

  const nom = isLngFr ?Cookies.get('family_name_FrCookie') : Cookies.get('family_name_ArCookie'); 
  setNom(nom);

const prenom = isLngFr ? Cookies.get('given_name_FrCookie') : Cookies.get('given_name_ArCookie');
  setPrenom(prenom); 

const genderC = Cookies.get('genreCookie'); 
const gender = genderC==="M" ? tEP("gender_male") :  tEP("gender_femelle");

  setGender(gender);

const nationalite = Cookies.get('nationaliteCookie'); 
setNationalite(nationalite);

const cin = Cookies.get('citizenidCookie'); 
setCin(cin);

let birthDate = Cookies.get('birthdateCookie')? Cookies.get('birthdateCookie') : "";
if(birthDate.length >0){
const year = birthDate.substring(0,4);
const month = birthDate.substring(4,6);
const day = birthDate.substring(6,8);
birthDate =`${day}/${month}/${year}`
}
setBirthDate(birthDate);

const bithPlace =  isLngFr ? Cookies.get('birthplace_FrCookie') : Cookies.get('birthplace_ArCookie');
setBirthDate(bithPlace); 

let adresseC =  isLngFr ? Cookies.get('address_FrCookie') : Cookies.get('address_ArCookie');
if(!adresseC){
  adresseC = Cookies.get('address_FrCookie')?Cookies.get('address_FrCookie') : "";
}
setAdresse(adresseC); 
if(adresseC.length >0){
const adresseParts =  adresseC.split(" ");
const cityC = adresseParts[adresseParts.length - 1];
if(cityC){ 
  setCity(cityC);
}
}
setValue("nom",nom);
setValue("prenom",prenom);
setValue("civilite",gender);
setValue("cin",cin);
setValue("ville",city);
setValue("lieuN",bithPlace);
setValue("dateN",birthDate);
setValue("adresse", adresse)

})
useEffect(()=>{
  console.log("values from local storage ", localStorage.getItem("image"));
  loadDataFromLocalStorage();
},[])
const loadDataFromLocalStorage=() =>{
  const data =   {
    reference: localStorage.getItem("reference") ? localStorage.getItem("reference") : "",
   adresse: localStorage.getItem("adresse") ? localStorage.getItem("adresse") : "",
   titre : localStorage.getItem("titre")? localStorage.getItem("titre") : "",
   province : localStorage.getItem("province") ? localStorage.getItem("province") : "",
   document : localStorage.getItem("document") ? localStorage.getItem("document") : "" ,
   dateFin : localStorage.getItem("dateFin") ?localStorage.getItem("dateFin") : "",
   dateDebut : localStorage.getItem("dateDebut")? localStorage.getItem("dateDebut") :"",
   image : localStorage.getItem("image")?localStorage.getItem("image") : "",
   date_restante : localStorage.getItem("date_restante")?localStorage.getItem("date_restante") : ""
 };
 setIsDataLoaded(true);
 setItem(data);
setReference(data.reference);
setDateDebut(data.dateDebut);
setDateFin(data.dateFin);
setPrefecture(data.province);
setCommune(data.commune);
setVille(data.ville);

}

 const onSubmit =  (data) => {
  const newData = {
    ...data,
    reference,
    dateDebut,
    dateFin, 
    commune,
    prefecture
  }
  console.log("data===",data);
  addNewOpposition.mutate(newData);

 

};
console.log("log addNewOpposition",addNewOpposition);

useEffect(() => {
      console.log("inside useEffect",addNewOpposition);
      if (addNewOpposition.customHandler.isSuccess ) {
        setIsSuccess(true);
           // setModalMessage(tRA("MESSAGE_SUCESS_BODY")); 
            setModalTitle(tEP("MESSAGE_SUCESS_NewOpp"));
            setIsModalOpen(true);
          
            setIcon(`fa-regular fa-circle-check ${styles.checkCrcleIcon}`);
      }
     }, [addNewOpposition.status]);
     useEffect(() => {
      if (addNewOpposition.customHandler.isError ) {
        if(addNewOpposition.customHandler.errorMessage== "Opposition already existe"){
        setModalTitle(tEP("title_err_NewOpp"));
        setModalMessage(tEP("message_err_NewOpp")); 
        setIcon(`fa-light fa-triangle-exclamation ${styles.exlamationIcon}`);
        setIsModalOpen(true); 
        }
      }
     }, [addNewOpposition.status]);

  

    const handleCloseModal = () => {
        setIsModalOpen(false);
        if (isSuccess) {
            navigate('/'); 
        }
    };
    return (
        <div id={styles.inquiryContentContainer}>
              <div className="breadcrumb">
                 <BreadCrumb parts={breadcrumbParts}/>
              </div>
        <div className={styles.inquiryContentContainer}>
                  {isDataLoaded &&
                  <InquiryCard  className={globalStyles.responsiveWeb} data={item}    />
}


                
  </div>   

        <div className={`${globalStyles.pageTitleContainer} ${globalStyles.responsiveWeb}`}>
            <div className={globalStyles.pageTitle}>{tEP("page_title")}</div>
            <div className={globalStyles.pageTitleDescription}>{tEP("page_description")}</div>
        </div>

        <div className={globalStyles.pageTitleContainer + " responsive-web"}>
                <div className={globalStyles.pageTitle}>{tEP("page_form_opposition_title")}</div>
        </div>

        <div className= {styles.boxborder}>
         <div className={styles.container}>
             <div>
             <div>
                <p className={`${translate("bold")}  text-base `+styles.headerNewOpp}>
                  {tEP("page_opposant_infos")}
                </p>
                <div className={styles.lineProp } ></div>
              </div>
              <div classNamex={styles.gridContainer}>
                <div className={styles.gridContainer}>
                  <InputForm
                    register={register}
                    errors={errors}
                    name={"civilite"}
                    label={tEP("new_opp_input1_label")}
                    placeholder={tEP("new_opp_input_placeholder")}
                    important
                  />
                  <InputForm
                    register={register}
                    errors={errors}
                    name={"prenom"}
                    label={tEP("new_opp_input2_label")}
                    placeholder={tEP("new_opp_input_placeholder")}
                    important
                    disable={true}
                  />

                    <InputForm
                    register={register}
                    errors={errors}
                    name={"nom"}
                    label={tEP("new_opp_input3_label")}
                    placeholder={tEP("new_opp_input_placeholder")}
                    important
                    disable={true}
                  />
                    {showFields && <InputForm
                  register={register}
                  errors={errors}
                  name={"nationalite"}
                  label={tEP("new_opp_input4_label")}
                  placeholder={tEP("new_opp_input_placeholder")}
                  important
                  disable={true}
                />
                    }
                </div>

                <div className={styles.gridContainer}>
                 <InputForm
                    register={register}
                    errors={errors}
                    name={"cin"}
                    label={tEP("new_opp_input5_label")}
                    placeholder={tEP("new_opp_input_placeholder")}
                    important
                    disable={true}
                  />
                
                   {showFields &&<InputForm
                    register={register}
                    errors={errors}
                    name={"dateN"}
                    label={tEP("new_opp_input6_label")}
                    placeholder={tEP("new_opp_input_placeholder")}
                    important
                    disable={true}
                  />
                   }

{showFields && <InputForm
                    register={register}
                    errors={errors}
                    name={"lieuN"}
                    label={tEP("new_opp_input7_label")}
                    placeholder={tEP("new_opp_input_placeholder")}
                    important
                    disable={true}
                  />
}
                    <InputForm
                  register={register}
                  errors={errors}
                  name={"profession"}
                  label={tEP("new_opp_input8_label")}
                  placeholder={tEP("new_opp_input_placeholder")}
                  
                />
                </div>

                <div>
                <p className={`${translate("bold")}  text-base `+styles.headerNewOpp}>
                  {tEP("page_opposant_adress")}
                </p>
                <div className={styles.lineProp } ></div>

                <div className={styles.gridContainer}>
        
                  <InputForm
                    register={register}
                    errors={errors}
                    name={"adresse"}
                    label={tEP("new_opp_input9_label")}
                    placeholder={tEP("new_opp_input_placeholder")}
                    important
                    disable={true}
                  />
                  
                  {showFields &&<InputForm
                    register={register}
                    errors={errors}
                    name={"ville"}
                    label={tEP("new_opp_input10_label")}
                    placeholder={tEP("new_opp_input_placeholder")}
                    important
                    disable={true}
                  />
}

                
                
                </div>
              </div>

              
              </div>
             </div>
             <div>
                <p className={`${translate("bold")}  text-base `+styles.headerNewOpp}>
                  {tEP("page_opposant_infos")}
                </p>
                <div className={styles.lineProp } ></div>
              </div>
             
              <div className={styles.gridContainer}>
        
        <InputForm
          register={register}
          errors={errors}
          name={"email"}
          label={tEP("new_opp_input12_label")}
          placeholder={tEP("new_opp_input_placeholder")}
          important
        />
        <InputForm
          register={register}
          errors={errors}
          name={"tel"}
          label={tEP("new_opp_input13_label")}
          placeholder={tEP("new_opp_input_placeholder")}
          important
        />
 
      
      </div>


      <div>
                <p className={`${translate("bold")}  text-base `+styles.headerNewOpp}>
                  {tEP("page_opposant_infos")}
                </p>
                <div className={styles.lineProp } ></div>
              </div>
             
              <div classNamex={styles.gridContainer}>
        
              <div>
              <TextAreaForm
                register={register}
                errors={errors}
                name={"opposition"}
                label={tEP("prop_act_input14_label")}
                placeholder={tEP("new_opp_input_placeholder")}
                important
              />
            </div>
       
      
      </div>
      
         </div>
         <div className="flex justify-end">
              <div className={styles.btnWith}>
                <Btn
                  backgroundColor={"#0369A1"}
                  className="flex justify-center items-center"
                  paddingX="25"
                  paddingY="10"
                  color="#fff"
                  content={tEP("envoyer")}
                  onClick={handleSubmit(onSubmit)} 
                >
                  <i class="fa-thin fa-paper-plane mx-2"></i>
                </Btn>
              </div>
            </div>

        </div>
     
        <Modal isOpen={isModalOpen}>
                <>
                <div  className="ed-modal-title"><i class={icon}></i></div>
                <div className={`${styles.modalTitle}`}>{modalTitle}</div>
                <div className={`${styles.modalMessage}`}>{modalMessage}</div>
                
                <button  className={`${styles.btnProp} ${translate("regular")} ` } onClick={handleCloseModal}>
                        {tEP("MESSAGE_RETOUR")}
                    </button>
                </>
            </Modal>
        </div>

        
    )

}

export default NewInquiry;