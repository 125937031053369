import {Tag} from "antd";
import {
    STATUS_COLORS_REFERENCE,
    STATUS_COLORS_REFERENCE_AR

} from "../util/constants";
export const NATIONAL_RANKING_COLUMNS = () => {
    return [
        {
            Header: '#',
            accessor: 'rank',
            color:"#E0F2FE",
            Cell:(e)=><BlackColumnColor value={e.value}/>,
            show: true,
        },
        {
            Header: 'tab_1_table_col_1',
            accessor: 'localite',
            Cell:(e)=>{
                const values=e.value.split(',');
                return <>
                    <BlackColumnColor value={values[0]} rootClassName="!flex"/>
                    {values[1] &&
                        <div className="flex justify-start">
                        <Tag color="default" bordered={false}>{values[1]}</Tag>
                        </div>}
                    </>
                    },
            show: true,
            nonSortable: true,
        },
        {
            Header: 'tab_1_table_col_2',
            accessor: 'rankComp',
            Cell:(e)=><ArrowChange value={e.value}/>,
            show: true,
        },
        {
            Header: 'tab_1_table_col_3',
            accessor: 'score',
            show: true,
        },
        {
            Header: 'tab_1_table_col_4',
            accessor: 'delai',
            show: true,
        },
        {
            Header: 'tab_1_table_col_5',
            accessor: 'attractivite',
            show: true,
        },
        {
            Header: 'tab_1_table_col_6',
            accessor: 'digital',
            Cell:(e)=><BlackColumnColor value={e.value}/>,
            show: true,
        },
        {
            Header: 'tab_1_table_col_7',
            accessor: 'ecosystem',
            show: true,
        },
        {
            Header: 'tab_1_table_col_8',
            accessor: 'fiscalite',
            show: true,
        },
    ];
};



const getStatusColor = (status) => {
    console.log("statuuuuus",status)
    return STATUS_COLORS_REFERENCE[status] || STATUS_COLORS_REFERENCE_AR[status] || STATUS_COLORS_REFERENCE['default'];
};

export const COLUMNS_TRACK_FOLDER_CIN = ( selectedReference, setSelectedReference ) => {
    return [
        {
            Header: '',
            accessor: 'rank',
            color:"#E0F2FE",
            Cell: ({ row }) => {
                console.log("row==xxx=",row);
                const isSelected = row.original.isCheked;
                return (
                    <div 
                    onClick={() => setSelectedReference(row.original.reference)}
                    style={{ cursor: 'pointer' }} 
                >
                    {isSelected && <i  class="fa-solid fa-check icon-color" style={{ color: '#7ed07e', fontSize: '20px' }} ></i>} {}
                </div>
                );
            },
            show: true,
        },
        {
            Header: 'tab_1_table_col_1',
            accessor: 'reference',
            Cell: (e) => <BlackColumnColor value={e.value} />,
            show: true,
            color: "#000000",
        },
        {
            Header: 'tab_1_table_col_2',
            accessor: 'consistance',
            Cell: (e) => <BlackColumnColor value={e.value} />,
            show: true,
            color: "#000000",
        },
        {
            Header: 'tab_1_table_col_3',
            accessor: 'status',
            Cell: (e) => {
                const status = e.value;
                const color = getStatusColor(status);
                return (
                    <Tag color={color} bordered={false}>
                        {status}
                    </Tag>
                );
            },
            show: true,
            nonSortable: true,
        }
        
    ];
};
const ArrowChange=(props)=> {
    let color=null
    let value=null
    let arrow = null;
    if (props.value > 0){
        color="text-green-700";
        value="+"+props.value;
        arrow = <i className="fa-solid fa-arrow-up"></i>
    }
    else if (props.value < 0) {
        color="text-red-700";
        value=props.value;
        arrow = <i className="fa-solid fa-arrow-down"></i>
    }
    else {
        color="text-blue-700";
        value="+"+0
        arrow = <i className="fa-solid fa-arrow-right"></i>
    }
    return (
        <div className={` flex gap-2 ${color}`}>
            {arrow}
            {value}
        </div>
    )
}
const BlackColumnColor=(props)=>{
    return(
        <div className={`text-[#1F2937] ${props.rootClassName}`}>
            {props.value}
        </div>
    )
}

